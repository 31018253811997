import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, TextField, Typography, Box, RadioGroup, Radio, FormControlLabel, FormGroup, FormControl, Checkbox, FormLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const QuizManagementPage = () => {
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [topic, setTopic] = useState('');
  const [difficulty, setDifficulty] = useState('easy');
  const [openModal, setOpenModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuizQuestions();
  }, []);

  const fetchQuizQuestions = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/quiz/questions', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setQuizQuestions(response.data);
    } catch (error) {
      console.error('Error fetching quiz questions:', error);
    }
  };

  const handleGenerateQuestion = async () => {
    try {
      const response = await axios.post('https://studiohoussem.com/quiz/generate', { topic, difficulty }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      const { questionText, answer } = response.data;
      setQuizQuestions([...quizQuestions, { questionText, answer }]);
      fetchQuizQuestions();
    } catch (error) {
      console.error('Error generating quiz question:', error);
    }
  };

  const handleGenerateNewQuestion = async (questionId) => {
    try {
      const response = await axios.put(`https://studiohoussem.com/quiz/questions/${questionId}?topic=${topic}&difficulty=${difficulty}`, null, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      const updatedQuestion = response.data;
      setQuizQuestions(quizQuestions.map(question => {
        if (question.id === questionId) {
          return updatedQuestion;
        }
        return question;
      }));
    } catch (error) {
      console.error('Error generating new quiz question:', error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px' }}>
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
      Quiz Management
    </Typography>

      <div style={{ marginBottom: '20px', width: '50%', textAlign: 'center' }}>
        <TextField
          label="Topic"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <RadioGroup row aria-label="difficulty" name="difficulty" value={difficulty} onChange={(e) => setDifficulty(e.target.value)} style={{ justifyContent: 'center', marginBottom: '20px' }}>
          <FormControlLabel value="easy" control={<Radio />} label="Easy" />
          <FormControlLabel value="medium" control={<Radio />} label="Medium" />
          <FormControlLabel value="hard" control={<Radio />} label="Hard" />
        </RadioGroup>
        <Button variant="contained" color="primary" onClick={handleGenerateQuestion}>
          Generate New Question
        </Button>
      </div>
      <div style={{ width: '80%' }}> {/* Adjust the width as needed */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizQuestions.map((question, index) => (
                <TableRow key={index}>
                  <TableCell>{question.questionText}</TableCell>
                  <TableCell>
                    <Button variant="outlined"  onClick={() => handleGenerateNewQuestion(question.id)}>Generate New</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 3px 5px rgba(0,0,0,0.3)' }}>
          <Typography variant="h6" gutterBottom>
            Edit Quiz Question
          </Typography>
          <TextField
            label="Question Text"
            value={selectedQuestion?.questionText}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
          />
        </Box>
      </Modal>
      <br></br>      <Button variant="outlined" onClick={() => navigate('/send')} style={{ marginBottom: '20px' }}>
        SEND QUIZ 
      </Button>
    </div>
    
  );
};

export default QuizManagementPage;
