import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Typography, Checkbox, FormControlLabel } from '@mui/material';
import './task.css';

function TaskManagement() {
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [newTaskDescriptions, setNewTaskDescriptions] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [filters, setFilters] = useState({
    pending: true,
    accepted: false,
    finished: false,
    notAccepted: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 5;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchTasksForDate(selectedDate);
  }, [selectedDate]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/users', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setUsers(response.data);
      const initialTaskDescriptions = {};
      response.data.forEach(user => {
        initialTaskDescriptions[user.userId] = '';
        fetchTasks(user.userId);
      });
      setNewTaskDescriptions(initialTaskDescriptions);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchTasks = async (userId) => {
    try {
      const response = await axios.get(`https://studiohoussem.com/tasks/${userId}`, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setTasks(prevTasks => ({
        ...prevTasks,
        [userId]: response.data
      }));
    } catch (error) {
      console.error(`Error fetching tasks for user ${userId}:`, error);
    }
  };

  const fetchTasksForDate = async (date) => {
    if (date) {
      const dateString = date.toISOString().split('T')[0];
      try {
        const response = await axios.get(`https://studiohoussem.com/tasks/date/${dateString}`, {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        });
        setTasks(response.data);
      } catch (error) {
        console.error(`Error fetching tasks for date ${dateString}:`, error);
      }
    } else {
      console.log("Selected date is null, do something else or handle this case accordingly.");
    }
  };

  const createTask = async (userId) => {
    try {
      const response = await axios.post(`https://studiohoussem.com/tasks/${userId}`, {
        description: newTaskDescriptions[userId],
        date: selectedDate
      }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      fetchTasks(userId);
      setNewTaskDescriptions({ ...newTaskDescriptions, [userId]: '' });
      const taskDescription = newTaskDescriptions[userId];
      await axios.post('https://studiohoussem.com/discord/sendToUser', {
        userId: userId,
        message: `New task added: ${taskDescription}`
      }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      alert('Task created successfully');
      const userRespondedYes = response.data;
      if (userRespondedYes) {
        await axios.put(`https://studiohoussem.com/tasks/${response.data.taskId}`, {
          status: 'completed'
        }, {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        });
        await axios.put(`https://studiohoussem.com/tasks/${response.data.taskId}`, {
          status: 'incomplete'
        }, {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        });
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const renderUserTasks = (userId) => {
    const userTasks = tasks[userId] || [];
    let filteredTasks = userTasks;
    if (selectedDate) {
      const taskDate = selectedDate.toLocaleDateString();
      filteredTasks = filteredTasks.filter(task => {
        const taskDateFormatted = new Date(task.date).toLocaleDateString();
        return taskDate === taskDateFormatted;
      });
    }
    filteredTasks = filteredTasks.filter(task => {
      return (!filters.pending || task.status === 'pending') &&
        (!filters.accepted || task.status === 'accepted') &&
        (!filters.finished || task.status === 'finished') &&
        (!filters.notAccepted || task.status === 'not accepted')
    });
    return filteredTasks;
  };

  const handleFilterChange = (filter) => {
    const updatedFilters = { ...filters, [filter]: !filters[filter] };
    setFilters(updatedFilters);
  };

  const handleSingleCheckboxChange = (filter) => {
    const updatedFilters = { ...filters };
    Object.keys(updatedFilters).forEach(key => {
      if (key !== filter) updatedFilters[key] = false;
    });
    setFilters({ ...updatedFilters, [filter]: !filters[filter] });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = users.slice(indexOfFirstTask, indexOfLastTask);

  return (
    <div className="container">
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Task Management
      </Typography>
      <div className="row">
        <div className="col-md-12">
          {showCalendar && (
            <Calendar
              onChange={setSelectedDate}
              value={selectedDate}
            />
          )}
          <button className="btn btn-primary mt-3 mb-3" onClick={() => setShowCalendar(!showCalendar)}>
            {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
          </button>
        </div>
        <div className="filter-controls">
          <FormControlLabel
            control={<Checkbox checked={filters.pending} onChange={() => handleSingleCheckboxChange('pending')} />}
            label="Pending"
          />
          <FormControlLabel
            control={<Checkbox checked={filters.accepted} onChange={() => handleSingleCheckboxChange('accepted')} />}
            label="Accepted"
          />
          <FormControlLabel
            control={<Checkbox checked={filters.finished} onChange={() => handleSingleCheckboxChange('finished')} />}
            label="Finished"
          />
          <FormControlLabel
            control={<Checkbox checked={filters.notAccepted} onChange={() => handleSingleCheckboxChange('notAccepted')} />}
            label="Not Accepted"
          />
        </div>
        <div className="col-md-12">
          {currentTasks.map(user => (
            <div key={user.userId} className="card mb-4">
              <div className="card-header">{user.username}</div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderUserTasks(user.userId).map(task => (
                      <tr key={task.id}>
                        <td>{task.description}</td>
                        <td>{task.status}</td>
                        <td>{new Date(task.createdAt).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="input-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="New Task"
                    value={newTaskDescriptions[user.userId]}
                    onChange={(e) => setNewTaskDescriptions({ ...newTaskDescriptions, [user.userId]: e.target.value })}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => createTask(user.userId)}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        {Array.from({ length: Math.ceil(users.length / tasksPerPage) }, (_, i) => (
          <button key={i} className="btn btn-secondary" onClick={() => paginate(i + 1)}>
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default TaskManagement;
