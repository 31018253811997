import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Typography, Box, TextField, TablePagination } from '@mui/material';

const AnnualScheduleManagement = () => {
  const [schedules, setSchedules] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [content, setContent] = useState('');
  const [dateToSend, setDateToSend] = useState('');
  const [channelIds, setChannelIds] = useState('');
  const [userIds, setUserIds] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/shedules/annual', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const updateSchedule = async () => {
    try {
      await axios.put(`https://studiohoussem.com/shedules/${selectedSchedule.scheduleId}`, {
        content,
        dateToSend,
        channelIds,
        userIds,
      }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Schedule updated successfully.');
      setOpenModal(false); // Close the modal after successful update
      fetchSchedules(); // Refresh the schedule list
    } catch (error) {
      console.error('Error updating schedule:', error);
    }
  };

  const deleteSchedule = async (scheduleId) => {
    try {
      await axios.delete(`https://studiohoussem.com/shedules/${scheduleId}`, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Schedule deleted successfully.');
      fetchSchedules(); // Refresh the schedule list
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px' }}>
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Annual Event Schedules Management
      </Typography>

      <div style={{ width: '80%' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Schedule ID</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Date to Send</TableCell>
                <TableCell>Channel IDs</TableCell>
                <TableCell>User IDs</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((schedule) => (
                <TableRow key={schedule.scheduleId}>
                  <TableCell>{schedule.scheduleId}</TableCell>
                  <TableCell>{schedule.content}</TableCell>
                  <TableCell>{formatDate(schedule.dateToSend)}</TableCell>
                  <TableCell>{schedule.channelIds?.join(', ')}</TableCell>
                  <TableCell>{schedule.userIds?.join(', ')}</TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => {
                      setSelectedSchedule(schedule);
                      setContent(schedule.content);
                      setDateToSend(schedule.dateToSend);
                      setChannelIds(schedule.channelIds?.join(', ') || '');
                      setUserIds(schedule.userIds?.join(', ') || '');
                      setOpenModal(true);
                    }}>Edit</Button>
                    <Button variant="outlined" color="error" onClick={() => deleteSchedule(schedule.scheduleId)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={schedules.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 3px 5px rgba(0,0,0,0.3)' }}>
          <Typography variant="h6" gutterBottom>
            Edit Schedule
          </Typography>
          <TextField label="Content" fullWidth value={content} onChange={(e) => setContent(e.target.value)} style={{ marginBottom: '10px' }} />
          <TextField label="Date to Send" fullWidth value={dateToSend} onChange={(e) => setDateToSend(e.target.value)} style={{ marginBottom: '10px' }} />
          <TextField label="Channel IDs" fullWidth value={channelIds} onChange={(e) => setChannelIds(e.target.value)} style={{ marginBottom: '10px' }} />
          <TextField label="User IDs" fullWidth value={userIds} onChange={(e) => setUserIds(e.target.value)} style={{ marginBottom: '10px' }} />
          <Button variant="contained" onClick={updateSchedule} style={{ marginTop: '20px' }}>Update</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AnnualScheduleManagement;

