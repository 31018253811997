import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Pagination } from '@mui/material';
import './faq.css'; // Importing the CSS file

const FAQ = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    fetchFAQs();
  }, [page]);

  const fetchFAQs = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/faq/all', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      const totalItems = response.data.length;
      const totalPages = Math.ceil(totalItems / 10); // Assuming 10 items per page
      setTotalPages(totalPages);

      const startIndex = (page - 1) * 10;
      const endIndex = startIndex + 10;
      const paginatedData = response.data.slice(startIndex, endIndex);
      setFaqList(paginatedData);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://studiohoussem.com/faq',
        { question, answer },
        {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        }
      );
      setSuccessMessage('FAQ question added successfully.');
      setErrorMessage('');
      setQuestion('');
      setAnswer('');
      fetchFAQs();
    } catch (error) {
      setErrorMessage('Error adding FAQ question.');
      setSuccessMessage('');
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDeleteFAQ = async (id) => {
    try {
      await axios.delete(`https://studiohoussem.com/faq/${id}`, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setSuccessMessage('FAQ deleted successfully.');
      setErrorMessage('');
      fetchFAQs();
    } catch (error) {
      setErrorMessage('Error deleting FAQ.');
      setSuccessMessage('');
    }
  };

  return (
    <Container maxWidth="md" className="faq-container">
            <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
      FAQs Questions
    </Typography>
      <Typography variant="h4" gutterBottom>Add FAQ </Typography>
      <form onSubmit={handleSubmit} className="faq-form">
        <TextField
          label="Question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">Add FAQ</Button>
      </form>
      {successMessage && <Typography variant="body1" color="success">{successMessage}</Typography>}
      {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}
      <Typography variant="h4" gutterBottom>Existing FAQs</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Actions</TableCell> {/* Add a new table cell for actions */}
            </TableRow>
          </TableHead>
          <TableBody>
            {faqList.map((faq, index) => (
              <TableRow key={index}>
                <TableCell>{faq.question}</TableCell>
                <TableCell>{faq.answer}</TableCell>
                <TableCell>
                <Button
  variant="outlined"  color="secondary"
  onClick={() => handleDeleteFAQ(faq.id)}
  style={{  color: 'red' }} // Transparent border for the delete button
>
  Delete
</Button>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer><br></br>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        className="pagination"
      /><br></br>
    </Container>
  );
};

export default FAQ;
