import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, FormControl, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import './send.css'; // Import CSS file

const SendQuizPage = () => {
  const [users, setUsers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [hour, setHour] = useState(12); // Default hour for scheduling
  const [minute, setMinute] = useState(0); // Default minute for scheduling
  const [sendDays, setSendDays] = useState([1, 2, 3, 4, 5]); // Default days for scheduling (Monday to Friday)
  const [displayedUsersCount, setDisplayedUsersCount] = useState(20);
  const [displayedChannelsCount, setDisplayedChannelsCount] = useState(20);

  useEffect(() => {
    fetchUsersAndChannels();
  }, []);

  const fetchUsersAndChannels = async () => {
    try {
      const usersResponse = await axios.get('https://studiohoussem.com/users', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      const channelsResponse = await axios.get('https://studiohoussem.com/channels', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setUsers(usersResponse.data);
      setChannels(channelsResponse.data);
    } catch (error) {
      console.error('Error fetching users and channels:', error);
    }
  };

  const handleCancelQuizSchedule = async () => {
    try {
      await axios.post('https://studiohoussem.com/discord/cancel', null, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Quiz schedule cancelled.');
    } catch (error) {
      console.error('Error cancelling quiz schedule:', error);
    }
  };

  const handleUserCheckboxChange = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const handleChannelCheckboxChange = (channelId) => {
    setSelectedChannels((prevSelectedChannels) =>
      prevSelectedChannels.includes(channelId)
        ? prevSelectedChannels.filter((id) => id !== channelId)
        : [...prevSelectedChannels, channelId]
    );
  };

  const handleSelectAllUsers = () => {
    setSelectedUsers(selectedUsers.length === users.length ? [] : users.map(user => user.userId));
  };

  const handleSelectAllChannels = () => {
    setSelectedChannels(selectedChannels.length === channels.length ? [] : channels.map(channel => channel.channelId));
  };

  const handleSendQuiz = async () => {
    try {
      const targetIds = [...selectedUsers, ...selectedChannels];
      await axios.post('https://studiohoussem.com/discord/send', { targetIds, quizQuestions }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Quiz sent successfully.');
    } catch (error) {
      console.error('Error sending quiz:', error);
    }
  };

  const handleScheduleQuiz = async () => {
    try {
      // Send request to schedule quiz sending
      const response = await axios.post('https://studiohoussem.com/discord/scheduleQuiz', {
        hour,
        minute,
        targetIds: [...selectedUsers, ...selectedChannels],
        sendDays
      }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Quiz scheduled successfully:', response.data);
    } catch (error) {
      console.error('Error scheduling quiz:', error);
    }
  };

  const handleDayCheckboxChange = (day) => {
    setSendDays((prevSendDays) =>
      prevSendDays.includes(day)
        ? prevSendDays.filter((d) => d !== day)
        : [...prevSendDays, day]
    );
  };

  const handleShowMoreUsers = () => {
    setDisplayedUsersCount(users.length);
  };

  const handleShowMoreChannels = () => {
    setDisplayedChannelsCount(channels.length);
  };

  return (
    <div className="container">
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Send The Quiz
      </Typography>
      <div className="formGroup">
        <FormControl component="fieldset">
          <FormGroup>
            <Button variant="outlined" color="primary" onClick={handleSelectAllUsers} className="checkboxButton">
              {selectedUsers.length === users.length ? 'Deselect All' : 'Select All Users'}
            </Button>
            <div className="checkboxGroup">
              {users.slice(0, displayedUsersCount).map((user) => (
                <FormControlLabel
                  key={user.id}
                  control={<Checkbox checked={selectedUsers.includes(user.userId)} onChange={() => handleUserCheckboxChange(user.userId)} />}
                  label={user.username}
                />
              ))}
            </div>
            {displayedUsersCount < users.length && (
              <Button variant="outlined" color="primary" onClick={handleShowMoreUsers}>
                Show More
              </Button>
            )}
          </FormGroup>
        </FormControl>
      </div>
      <div>
        <FormControl component="fieldset">
          <FormGroup>
            <Button variant="outlined" color="primary" onClick={handleSelectAllChannels} className="checkboxButton">
              {selectedChannels.length === channels.length ? 'Deselect All' : 'Select All Channels'}
            </Button>
            <div className="checkboxGroup">
              {channels.slice(0, displayedChannelsCount).map((channel) => (
                <FormControlLabel
                  key={channel.id}
                  control={<Checkbox checked={selectedChannels.includes(channel.channelId)} onChange={() => handleChannelCheckboxChange(channel.channelId)} />}
                  label={channel.channelName}
                />
              ))}
            </div>
            {displayedChannelsCount < channels.length && (
              <Button variant="outlined" color="primary" onClick={handleShowMoreChannels}>
                Show More
              </Button>
            )}
          </FormGroup>
        </FormControl>
      </div>
      <div style={{ marginTop: '20px' }}>
        <div className="scheduleContainer">
          <TextField
            label="Hour"
            type="number"
            value={hour}
            onChange={(e) => setHour(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <span>:</span>
          <TextField
            label="Minute"
            type="number"
            value={minute}
            onChange={(e) => setMinute(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <div className="dayCheckboxes">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={sendDays.includes(index)} onChange={() => handleDayCheckboxChange(index)} />}
                label={day}
              />
            ))}
          </div>
        </div>

        <Button variant="contained" color="primary" onClick={handleScheduleQuiz} >
          Schedule Quiz
        </Button>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSendQuiz}>Send Quiz Now</Button>
         <Button variant="contained" color="secondary" onClick={handleCancelQuizSchedule}>
          Cancel Quiz Schedule
        </Button>
      </div>
    </div>
  );
};

export default SendQuizPage;
