import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';

const ScheduleMessage = () => {
  const [formData, setFormData] = useState({
    content: '',
    dateToSend: '',
    userIds: [],
    channelIds: [],
    sender: '',
    isContinuous: false,
    daysOfWeek: []
  });
  const [users, setUsers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [showMoreUsers, setShowMoreUsers] = useState(false);
  const [showMoreChannels, setShowMoreChannels] = useState(false);

  useEffect(() => {
    const fetchUsersAndChannels = async () => {
      try {
        const usersResponse = await axios.get('https://studiohoussem.com/users', {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        });
        const channelsResponse = await axios.get('https://studiohoussem.com/channels', {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        });
        setUsers(usersResponse.data);
        setChannels(channelsResponse.data);
      } catch (error) {
        console.error('Error fetching users and channels:', error);
      }
    };

    fetchUsersAndChannels();
  }, []);

  const handleSelectAllUsers = () => {
    setFormData(prevState => ({
      ...prevState,
      userIds: prevState.userIds.length === users.length ? [] : users.map(user => user.userId)
    }));
  };

  const handleSelectAllChannels = () => {
    setFormData(prevState => ({
      ...prevState,
      channelIds: prevState.channelIds.length === channels.length ? [] : channels.map(channel => channel.channelId)
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'userIds' || name === 'channelIds') {
      setFormData({ ...formData, [name]: checked ? [...formData[name], value] : formData[name].filter(id => id !== value) });
    } else if (name === 'isContinuous') {
      setFormData({ ...formData, isContinuous: checked, daysOfWeek: checked ? [] : formData.daysOfWeek });
    } else if (name === 'daysOfWeek') {
      const selectedDays = formData.daysOfWeek.includes(value)
        ? formData.daysOfWeek.filter(day => day !== value)
        : [...formData.daysOfWeek, value];
      setFormData({ ...formData, daysOfWeek: selectedDays });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://studiohoussem.com/shedules', formData, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      alert('Message scheduled successfully!');
      setFormData({
        content: '',
        dateToSend: '',
        userIds: [],
        channelIds: [],
        sender: '',
        isContinuous: false,
        daysOfWeek: []
      });
    } catch (error) {
      console.error('Error scheduling message:', error);
      alert('An error occurred while scheduling the message: ' + error.message);
    }
  };

  const handleShowMoreUsers = () => {
    setShowMoreUsers(!showMoreUsers);
  };

  const handleShowMoreChannels = () => {
    setShowMoreChannels(!showMoreChannels);
  };

  return (
    <div className="container mx-auto px-4 py-8 text-black">
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Schedule Message
      </Typography>
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <TextField
            label="Content"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={formData.content}
            name="content"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <TextField
            label="Date to Send"
            type="datetime-local"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.dateToSend}
            name="dateToSend"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <Typography variant="subtitle1">Select Users</Typography>
          <FormControlLabel
            control={<Checkbox checked={formData.userIds.length === users.length} onChange={handleSelectAllUsers} />}
            label={formData.userIds.length === users.length ? 'Deselect All' : 'Select All Users'}
          />
          <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {users.slice(0, showMoreUsers ? users.length : 5).map(user => (
              <FormControlLabel
                key={user.userId}
                control={<Checkbox checked={formData.userIds.includes(user.userId)} onChange={handleInputChange} value={user.userId} name="userIds" />}
                label={user.username}
              />
            ))}
          </FormGroup>
          {users.length > 5 && (
            <Button onClick={handleShowMoreUsers}>{showMoreUsers ? 'Show Less' : 'Show More'}</Button>
          )}
        </div>
        <div className="mb-4">
          <Typography variant="subtitle1">Select Channels</Typography>
          <FormControlLabel
            control={<Checkbox checked={formData.channelIds.length === channels.length} onChange={handleSelectAllChannels} />}
            label={formData.channelIds.length === channels.length ? 'Deselect All' : 'Select All Channels'}
          />
          <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {channels.slice(0, showMoreChannels ? channels.length : 5).map(channel => (
              <FormControlLabel
                key={channel.channelId}
                control={<Checkbox checked={formData.channelIds.includes(channel.channelId)} onChange={handleInputChange} value={channel.channelId} name="channelIds" />}
                label={channel.channelName}
              />
            ))}
          </FormGroup>
          {channels.length > 5 && (
            <Button onClick={handleShowMoreChannels}>{showMoreChannels ? 'Show Less' : 'Show More'}</Button>
          )}
        </div>
        <div className="mb-4">
          <TextField
            label="Sender"
            variant="outlined"
            fullWidth
            value={formData.sender}
            name="sender"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <FormControlLabel
            control={<Checkbox checked={formData.isContinuous} onChange={handleInputChange} name="isContinuous" />}
            label="Schedule Continuously"
          />
        </div>
        {formData.isContinuous && (
          <div className="mb-4">
            <Typography variant="subtitle1">Select Days of the Week</Typography>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={formData.daysOfWeek.includes(index.toString())} onChange={handleInputChange} value={index} name="daysOfWeek" />}
                label={day}
              />
            ))}
          </div>
        )}
        <Button type="submit" variant="contained" color="primary">Schedule</Button>
      </form>
    </div>
  );
};

export default ScheduleMessage;
