import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import ScheduleMessage from './component/schedule/ScheduleMessage';
import ResumeViewer from './component/summary/Resume';
import TaskManagement from './component/task/TaskManagement';
import Home from './component/home';
import QuizManagementPage from './component/quiz/quiz';
import SendQuizPage from './component/quiz/sendQuiz';
import ResumeMManagement from './component/summary/ResumeM';
import ResumeWManagement from './component/summary/ResumeW';
import UserManagement from './component/user/user';
import Faq from './component/faq/Faq';
import './App.css';
import './tailwind.css';
import ContinuousScheduleManagement from './component/schedule/continious';
import ScheduleManagement from './component/schedule/schedules';
import ScheduleMessageA from './component/schedule/scheduleAnnual';
import AnnualScheduleManagement from './component/schedule/annual';
import LoginForm from './component/auth/LoginForm';
import PasswordChangeForm from './component/auth/passwordChange';



function NavbarToggleable() {

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">BOTDIS</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <NavDropdown title="Schedule" id="resumeDropdown">
                    <NavDropdown.Item as={Link} to="/schedule">Schedule Event</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/scheduleA">Schedule Annual Event</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/schedules">Current Schedules</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/continious">Continous Schedules</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/scheduleAM">Annual Schedules</NavDropdown.Item>
                  </NavDropdown>
              <NavDropdown title="Get Summary Discussion" id="resumeDropdown">
              <NavDropdown.Item as={Link} to="/resume">Daily Resume</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/resumew">Weekly Resume</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/resumem">Monthly Resume</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Quiz" id="resumeDropdown">
                  <NavDropdown.Item as={Link} to="/quiz">Generate Quiz Questions</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/send">Send Quiz</NavDropdown.Item>
                  </NavDropdown>            <Nav.Link as={Link} to="/faq" activeClassName="active">FAQs</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link as={Link} to="/users" activeClassName="active">Users</Nav.Link>
            <Nav.Link as={Link} to="/task" activeClassName="active">Task</Nav.Link>
            <Nav.Link as={Link} to="/passChange" activeClassName="active">Update Password</Nav.Link>

          </Nav>
          <form className="d-flex input-group w-auto ms-lg-3 my-3 my-lg-0">
            <input type="search" className="form-control custom-search-input" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-white custom-search-button" type="button">Search</button>
          </form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function App() {
  const isMobile = window.innerWidth <= 768; // Define a breakpoint for mobile screens
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Change this to true if the user is authenticated
  console.log('isAuthenticated:', isAuthenticated);
  return (
    <Router>
      <div className="app-container">
        {isMobile ? (
          <NavbarToggleable />
        ) : (
          <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Container fluid>
              <Navbar.Brand as={Link} to="/">BOTDIS</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                <NavDropdown title="Schedule" id="resumeDropdown">
                <NavDropdown.Item as={Link} to="/schedule">Schedule Event</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/scheduleA">Schedule Annual Event</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/schedules">Current Schedules</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/continious">Continous Schedules</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/scheduleAM">Annual Schedules</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Get Summary Discussion" id="resumeDropdown">
                    <NavDropdown.Item as={Link} to="/resume">Daily Summary</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/resumew">Weekly Summary</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/resumem">Monthly Summary</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Quiz" id="resumeDropdown">
                  <NavDropdown.Item as={Link} to="/quiz">Generate Quiz Questions</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/send">Send Quiz</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to="/faq" activeClassName="active">FAQs</Nav.Link>
                </Nav>
                <Nav>
                  <Nav.Link as={Link} to="/users" activeClassName="active">Users</Nav.Link>
                  <Nav.Link as={Link} to="/task" activeClassName="active">Task</Nav.Link>
                  <Nav.Link as={Link} to="/passChange" activeClassName="active">Update Password</Nav.Link>

                </Nav>
                <form className="d-flex input-group w-auto ms-lg-3 my-3 my-lg-0">
            <input type="search" className="form-control custom-search-input" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-white custom-search-button" type="button">Search</button>
          </form>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        )}
        <Routes>
          <Route path="/" element={!isAuthenticated ? <Navigate to="/login" /> :<Home />} />
          <Route path="/schedule" element={!isAuthenticated ? <Navigate to="/login" /> :<ScheduleMessage />} />
          <Route path="/resume" element={!isAuthenticated ? <Navigate to="/login" /> : <ResumeViewer />} />
          <Route path="/quiz" element={!isAuthenticated ? <Navigate to="/login" /> : <QuizManagementPage />} />
          <Route path="/task" element={!isAuthenticated ? <Navigate to="/login" /> :<TaskManagement />} />
          <Route path="/send" element={!isAuthenticated ? <Navigate to="/login" /> :<SendQuizPage />} />
          <Route path="/resumew" element={!isAuthenticated ? <Navigate to="/login" /> :<ResumeWManagement />} />
          <Route path="/resumem" element={!isAuthenticated ? <Navigate to="/login" /> :<ResumeMManagement />} />
          <Route path="/users" element={!isAuthenticated ? <Navigate to="/login" /> :<UserManagement />} />
          <Route path="/faq" element={!isAuthenticated ? <Navigate to="/login" /> :<Faq />} />
          <Route path="/continious" element={!isAuthenticated ? <Navigate to="/login" /> :<ContinuousScheduleManagement />} />
          <Route path="/schedules" element={!isAuthenticated ? <Navigate to="/login" /> :<ScheduleManagement />} />
          <Route path="/scheduleA" element={!isAuthenticated ? <Navigate to="/login" /> :<ScheduleMessageA />} />
          <Route path="/scheduleAM" element={!isAuthenticated ? <Navigate to="/login" /> :<AnnualScheduleManagement />} />
          <Route path="/login" element={<LoginForm setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/passChange" element={!isAuthenticated ? <Navigate to="/login" /> :<PasswordChangeForm />} />






        </Routes>
      </div>
      {/* Footer */}
      <footer className="bg-dark text-white text-center">
        <div className="text-center p-3" style={{ backgroundColor: '#7289DA', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}>
          <span className="fs-5 fw-bold">© 2024 BOTDIS</span>
        </div>
      </footer>
    </Router>
  );
}

export default App;
