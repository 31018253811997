import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Blue color for primary elements
    },
    secondary: {
      main: '#dc004e', // Pink color for secondary elements
    },
  },
});

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [pointsToAddMap, setPointsToAddMap] = useState({});
  const [messageMap, setMessageMap] = useState({});

  useEffect(() => {
    fetchAllUsers();
  }, [currentPage]);

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/users', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const addPointsToUser = async (userId, points) => {
    try {
      setIsLoading(true);
      await axios.post(`https://studiohoussem.com/users/${userId}/add-points`, { pointsToAdd: points }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      fetchAllUsers();
    } catch (error) {
      console.error('Error adding points:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendToUser = async (userId, msg) => {
    try {
      setIsLoading(true);
      await axios.post('https://studiohoussem.com/discord/sendToUser', { userId, message: msg }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePointsChange = (userId, value) => {
    setPointsToAddMap(prevState => ({
      ...prevState,
      [userId]: value,
    }));
  };

  const handleMessageChange = (userId, value) => {
    setMessageMap(prevState => ({
      ...prevState,
      [userId]: value,
    }));
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <br />
        <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
          User Management
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Add Points</TableCell>
                <TableCell>Send Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentUsers.map(user => (
                <TableRow key={user.userId}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.points}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={pointsToAddMap[user.userId] || ''}
                      onChange={(e) => handlePointsChange(user.userId, parseInt(e.target.value))}
                    />
                    <Button color="primary" onClick={() => addPointsToUser(user.userId, pointsToAddMap[user.userId])} disabled={!pointsToAddMap[user.userId] || isLoading}>Add</Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      value={messageMap[user.userId] || ''}
                      onChange={(e) => handleMessageChange(user.userId, e.target.value)}
                    />
                    <Button color="secondary" onClick={() => sendToUser(user.userId, messageMap[user.userId])} disabled={!messageMap[user.userId] || isLoading}>Send</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <Button onClick={() => paginate(currentPage + 1)} disabled={indexOfLastItem >= users.length}>Next</Button>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default UserManagement;
