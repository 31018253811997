import React, { useState } from 'react';
import axios from 'axios';
import './PasswordChangeForm.css';

const PasswordChangeForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await axios.post('https://studiohoussem.com/admin/auth/change-password', { currentPassword, newPassword }, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setSuccessMessage(response.data.message);
      setCurrentPassword('');
      setNewPassword('');
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2>Update Password</h2>
        <div className="form-group">
          <label htmlFor="current-password">Current Password:</label>
          <input
            type="password"
            id="current-password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="new-password">New Password:</label>
          <input
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" style={{ width: '100%' }}>Change Password</button>
        {error && <div className="error">{error}</div>}
        {successMessage && <div className="success">{successMessage}</div>}

      </form>
    </div>
  );
};

export default PasswordChangeForm;
