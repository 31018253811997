import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Typography, Box } from '@mui/material';

const ContinuousScheduleManagement = () => {
  const [continuousSchedules, setContinuousSchedules] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchContinuousSchedules();
  }, []);

  const fetchContinuousSchedules = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/shedules/continuous', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setContinuousSchedules(response.data);
    } catch (error) {
      console.error('Error fetching continuous schedules:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const stopContinuousSchedule = async (scheduleId) => {
    try {
      await axios.put(`https://studiohoussem.com/shedules/${scheduleId}/stop-continuous`, {}, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Continuous schedule stopped successfully.');
      fetchContinuousSchedules(); // Refresh the list of continuous schedules
    } catch (error) {
      console.error('Error stopping continuous schedule:', error);
    }
  };


  const deleteContinuousSchedule = async (scheduleId) => {
    try {
      await axios.delete(`https://studiohoussem.com/shedules/${scheduleId}`, {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      console.log('Continuous schedule deleted successfully.');
      fetchContinuousSchedules(); // Refresh the list of continuous schedules
    } catch (error) {
      console.error('Error deleting continuous schedule:', error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px' }}>
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Continuous Schedule Management
      </Typography>

      <div style={{ width: '80%' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Schedule ID</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Date to Send</TableCell>
                <TableCell>Channel IDs</TableCell>
                <TableCell>User IDs</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {continuousSchedules.map((schedule, index) => (
                <TableRow key={index}>
                  <TableCell>{schedule.scheduleId}</TableCell>
                  <TableCell>{schedule.content}</TableCell>
                  <TableCell>{formatDate(schedule.dateToSend)}</TableCell>
                  <TableCell>{schedule.channelIds?.join(', ')}</TableCell>
                  <TableCell>{schedule.userIds?.join(', ')}</TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => stopContinuousSchedule(schedule.scheduleId)}>Stop</Button>
                    <Button variant="outlined" color="error" onClick={() => deleteContinuousSchedule(schedule.scheduleId)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Modal for editing continuous schedule */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 3px 5px rgba(0,0,0,0.3)' }}>
          <Typography variant="h6" gutterBottom>
            Edit Continuous Schedule
          </Typography>
          {/* Add form elements for editing continuous schedule */}
        </Box>
      </Modal>
    </div>
  );
};

export default ContinuousScheduleManagement;
