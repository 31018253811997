import React, { useState, useEffect } from 'react';
import './home.css'; // Importing the CSS file

function Home() {
  const texts = [
    "HELLO",
    "ADMIN",
    "I'M",
    "Botdis",
    "Please",
    "Give",
    "a Love",
    ":)",
    "by @IHEB"
  ];

  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="container">
      <span className="text">{texts[textIndex]}</span>
    </div>
  );
}

export default Home;
