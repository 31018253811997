import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Container, Typography, Button, Pagination } from '@mui/material'; // Import MUI components

function ResumeWManagement() {
  const [resumes, setResumes] = useState([]);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resumesPerPage] = useState(10);

  useEffect(() => {
    if (selectedMonth) {
      fetchResumesByMonth(selectedMonth);
    } else {
      fetchAllResumes();
    }
  }, [selectedMonth]);

  const fetchAllResumes = async () => {
    try {
      const response = await axios.get('https://studiohoussem.com/resumeW/all', {
        headers: {
          'x-secret-key': 'bot00001s'
        }
      });
      setResumes(response.data);
    } catch (error) {
      setError('Error fetching resumes. Please try again later.');
    }
  };

  const fetchResumesByMonth = async (month) => {
    try {
      const year = month.getFullYear();
      const monthIndex = month.getMonth();
      const numberOfDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();
      const requests = [];

      for (let day = 1; day <= numberOfDaysInMonth; day++) {
        const formattedDate = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const request = axios.get(`https://studiohoussem.com/resumeW/by-date?date=${formattedDate}`, {
          headers: {
            'x-secret-key': 'bot00001s'
          }
        });
        requests.push(request);
      }

      const responses = await Promise.all(requests);
      const resumes = responses.flatMap(response => response.data);
      setResumes(resumes);
    } catch (error) {
      setError('Error fetching resumes by month. Please try again later.');
    }
  };

  // Logic for pagination
  const indexOfLastResume = currentPage * resumesPerPage;
  const indexOfFirstResume = indexOfLastResume - resumesPerPage;
  const currentResumes = resumes.slice(indexOfFirstResume, indexOfLastResume);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container maxWidth="md" className="resume-management-container">
      <Typography variant="h3" gutterBottom className="text-center font-semibold text-4xl mb-8">
        Weekly Summary
      </Typography>
      <div className="calendar-toggle">
        <Button variant="contained" color="primary" onClick={() => setShowCalendar(!showCalendar)}>
          {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
        </Button>
        {showCalendar && (
          <Calendar
            onClickMonth={(value) => setSelectedMonth(new Date(value))}
            value={selectedMonth}
            calendarType="US"
            showNeighboringMonth={false}
            showNavigation={false}
            showDoubleView={false}
            view="year"
          />
        )}
      </div>
      {(!showCalendar && resumes.length > 0) && (
        <div className="resumes mt-8">
          {error && <div className="error">{error}</div>}
          <div className="resume-container">
            {currentResumes.map((resume, index) => (
              <div key={index} className="resume-item border-b border-gray-200 py-4">
                <Typography variant="h4" gutterBottom className="text-lg font-semibold">
                  {new Date(resume.generatedAt).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" className="text-base text-gray-600">
                  {resume.message}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}
      <Pagination
        count={Math.ceil(resumes.length / resumesPerPage)}
        page={currentPage}
        onChange={(event, value) => paginate(value)}
        color="primary"
        className="pagination mt-8"
      />
    </Container>
  );
}

export default ResumeWManagement;
